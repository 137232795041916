import { ModuleBase, Link, FormPopup, ResponsiveImage, HeadingTag } from 'components';
import { useRouter } from 'next/router';
import ArrowRight from 'assets/arrowRight.svg';
import { isColorDark } from 'utils';

const CTacontactFormModule = ({ data }) => {
  const router = useRouter();

  const addQueryParam = (params) => {
    router.replace(
      {
        query: { ...router.query, ...params },
      },
      undefined,
      { shallow: true },
    );
  };

  return (
    <ModuleBase data={data}>
      <FormPopup form={data.formModule} />
      <div
        className="container flex flex-col pt-16 md:pt-20 lg:pt-26 pb-16 md:pb-20 lg:pb-26 
      items-center lg:justify-between gap-8 md:gap-16 font-montserrat lg:flex-row"
      >
        <div className="w-fit h-fit gap-0 grid grid-cols-2">
          {data.imageCTAs.map((imageCTA, index) => (
            <Link link={imageCTA.ctaLink} key={index} className="flex items-center ">
              <div className="relative w-full h-full  min-h-[300px] sm:min-h-[368px] flex items-center ">
                {imageCTA.image && (
                  <ResponsiveImage
                    image={imageCTA.image}
                    widths={{ xs: 300, md: 300, lg: 300 }}
                    heights={{ xs: 368, md: 368, lg: 368 }}
                    className="h-full w-full object-cover"
                    image-class="h-full w-full object-cover"
                  />
                )}

                <div
                  className="btn imageCta w-max bg-black/50 px-5 py-2 rounded-full font-montserrat text-brand-soft-white
                text-xs not-italic font-medium leading-normal tracking-quote-reg-mobile uppercase absolute top-1/2 left-1/2 
                -translate-y-1/2 -translate-x-1/2"
                >
                  <span className="font-montserrat text-xs not-italic font-medium leading-normal tracking-quote-reg-mobile uppercase">
                    {imageCTA.title}
                  </span>
                </div>
              </div>
            </Link>
          ))}
        </div>

        <div className="flex flex-col justify-between w-full sm:w-[468px] lg:w-1/2 lg:p-10 font-montserrat font-normal">
          {data.heading && (
            <HeadingTag
              data={data.heading}
              className="font-didot color-from-bg whitespace-pre-wrap text-title-l not-italic font-normal leading-10 -tracking-headline-m-mobile w-fit
                            text-left w-[468px] pb-[24px]
                            md:text-title-xl md:leading-[44px] md:-tracking-headline-l-mobile
                            lg:mx-0 lg:w-fit lg:text-[64px] lg:leading-[80px] lg:-tracking-headline-s [&_.indented]:ml-0 [&_.indented]:ml-11"
            />
          )}
          {data.text && (
            <div
              className="color-from-bg description text-sm leading-6 mt-4 lg:-tracking-[0.14px]  max-w-[392px] pb-[60px]"
              dangerouslySetInnerHTML={{ __html: data.text }}
            />
          )}
          {data.ctaLabel && (
            <button
              className={`btn border-black/25 mt-[13.6px] justify-start px-[24px] w-fit ${isColorDark(data.backgroundColour) ? 'secondary border-brand-soft-white ' : 'primary'}
              `}
              onClick={() => addQueryParam({ form: true })}
            >
              <span>{data.ctaLabel.toLocaleUpperCase(router.locale)}</span>
              <ArrowRight />
            </button>
          )}
        </div>
      </div>
    </ModuleBase>
  );
};

export default CTacontactFormModule;
