import { default as NextLink } from 'next/link';
import PropTypes from 'prop-types';
import { convertAbsoluteLinkToRelative } from 'utils';
import { useTranslation } from 'next-i18next';
import { useSelector } from 'react-redux';
import { selectVirtualHost } from '@/store/index';

const Link = ({ link, children, ariaLabel, ...props }) => {
  const virtualHost = useSelector(selectVirtualHost);
  const fixedLink = convertAbsoluteLinkToRelative(link, virtualHost);
  const { t } = useTranslation('common');

  return link ? (
    <NextLink
      href={fixedLink?.href || ''}
      target={fixedLink?.target}
      aria-label={fixedLink?.target === '_blank' ? t('general.$opensanewwindow', { ariaLabel }) || link?.text : null}
      {...props}
    >
      {children || link?.text}
    </NextLink>
  ) : null;
};

Link.propTypes = {
  link: PropTypes.object,
};

export default Link;
