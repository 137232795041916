export const isExternalLink = (link) => {
  if (link) {
    const { href } = link;
    return (
      href &&
      (href.toLowerCase().startsWith('http://') ||
        href.toLowerCase().startsWith('https://') ||
        href.toLowerCase().startsWith('//') ||
        href.toLowerCase().startsWith('mailto:'))
    );
  }
  return true;
};

export const openLink = (router, link) => {
  if (link) {
    const { href, target } = link;
    if (target && target === '_blank') {
      window.open(href, '_blank').focus();
    } else if (isExternalLink(link)) {
      window.location.href = href;
    } else {
      router.push(href);
    }
  }
};

export const isValidHttpUrl = (string) => {
  let url = null;

  if (string && string.length > 0) {
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
  } else {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

// convert absolute URLs to relative if same host
export const convertAbsoluteLinkToRelative = (link, host) => {
  if (link && isExternalLink(link) && host) {
    let linkUrl = null;

    try {
      linkUrl = new URL(link.href);
    } catch (e) {
      console.warn('Invalid URL: ' + link.href);
    }

    if (linkUrl && host === linkUrl.host) {
      return { ...link, href: linkUrl.pathname + linkUrl.search };
    }
  }

  return link;
};
